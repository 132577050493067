<template>
  <div>
    <div class="task" :class="{'task-pt': title.length === 0}">
      <div class="task__content">
        <div class="level" v-for="(item, index) in list" :key="index">
          <div class="level__title">关卡{{index + 1}}: 《{{item.taskLevelName}}》</div>
          <div class="level__content">
            <div
              class="level__item flex flex__space-between align-center"
              v-for="(ele, id) in item.learningTaskSubTaskProgressList"
              :key="id"
              @click="click(ele, index, id, item.studyType, item.taskLevelId)"
            >
              <div class="item_image" v-if="ele.progressStatus === 'FINISH'">
                <img src="@/assets/images/homeImg/trueIcon.svg" alt="">
              </div>
              <div class="item_image" v-if="ele.progressStatus === 'NOT_START'">
                <img src="@/assets/images/homeImg/noIcon.svg" alt="">
              </div>
              <div
                class="level__item-text"
                :class="{'level__item-text_active': levelSubTaskId === ele.levelSubTaskId && levelSubTaskContentId === ele.levelSubTaskContentId}"
                ref="init"
              >
                <span class="type">【{{ele.subTaskType === 'COURSE' ? '视频' : '考试'}}】</span>
                <a-tooltip placement="bottom" :title="ele.levelSubTaskName">{{ele.levelSubTaskName}}</a-tooltip>
              </div>
              <!-- <div v-if="ele.progressStatus === 'FINISH'" class="level__item-garde">
                <img src="@/assets/images/homeImg/play_ywc.png" alt="">
              </div>
              <div class="level__item-garde" v-if="ele.progressStatus === 'NOT_START'">
                <img src="@/assets/images/homeImg/play_wwc.png" alt="">
              </div> -->
            </div>
          </div>
        </div>
        <div v-if="list.length <= 0" class="nodata">
          <a-empty />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isTitle: {
      type: Boolean,
      default: true
    },
    title: {
      type: String,
      default: ""
    },
    list: {
      type: Array,
      default: () => {
        return [];
      }
    },
    studyType: {
      type: String,
      default: "PASS_MODE" // 默认闯关模式
    },
    status: {
      type: String //任务状态
      // default: "PASS_MODE" //
    },
    disabled: {
      type: Boolean,
      default: false
    },
    lockFlag: {
      type: Number,
      default: 0
    },
    isClick: {
      type: Boolean,
      default: true
    },
    passFlag: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      video: require("@/assets/images/home/play.png"),
      exam: require("@/assets/images/home/exam.png"),
      levelSubTaskId: "",
      levelSubTaskContentId: ""
    };
  },
  created() {
    console.log(this.$route.query);
    this.levelSubTaskId = this.$route.query.id;
    this.levelSubTaskContentId = this.$route.query.course;
  },
  methods: {
    click(item, index, id, studyType, taskLevelId) {
      console.log(item, index, id, studyType, taskLevelId)
      if (!this.isClick) {
        this.$message.warning("操作频繁，请稍后！");
        return false;
      }
      if (this.lockFlag === 1) {
        // 任务锁定
        this.$message.warning("抱歉! 该任务还未开启, 请联系管理员");
        return false;
      }
      if (this.disabled) return false; // 不能点击跳转
      if (index <= 0) {
        // 点击第一个关卡时
        this.subTaskStudyType(item, index, id, studyType, taskLevelId);
        return false;
      }
      if (item.progressStatus === "FINISH") {
        // g关卡为完成状态时
        // 如果已经获取学分的课程则不限制
        this.toDetails(item, taskLevelId);
        return false;
      }
      let count = 0;
      console.log(this.list, "list");
      this.list.some((ele, indexes) => {
        if (indexes < index) {
          // 找到当前关卡之前的关卡
          if (this.studyType === "PASS_MODE") {
            // 学习任务的学习模式判断
            if (ele.progressStatus === "FINISH" || ele.skipFlag === "YES")
              count++;
          } else {
            this.subTaskStudyType(item, index, id, studyType, taskLevelId);
            return item;
          }
        } else {
          console.log(count, "count");
          console.log(indexes, "indexes");
          if (count === indexes) {
            console.log(indexes, "索引");
            console.log(count, "跳转");
            this.subTaskStudyType(item, index, id, studyType, taskLevelId);
            return item;
          } else {
            this.$message.warning("请先完成上一个关卡!");
            return item;
          }
        }
      });
    },
    // 关卡的子任务学习模式判断
    subTaskStudyType(item, index, id, studyType, taskLevelId) {
      if (studyType === "PASS_MODE") {
        if (
          id != 0 &&
          this.list[index].learningTaskSubTaskProgressList[id - 1]
            .progressStatus != "FINISH"
        ) {
          this.$message.warning("请先完成上一个课程任务!");
        } else {
          this.toDetails(item, taskLevelId);
        }
      } else {
        this.toDetails(item, taskLevelId);
      }
    },
    toDetails(item, taskLevelId) {
      let id = this.$route.query.id;
      let course = this.$route.query.course;
      if (id === item.levelSubTaskId && course === item.levelSubTaskContentId) {
        return false;
      }
      this.levelSubTaskId = item.levelSubTaskId;
      this.levelSubTaskContentId = item.levelSubTaskContentId;
      if (item.subTaskType === "COURSE") {
        this.$router.push({
          name: "videoDetails",
          query: {
            id: item.levelSubTaskId,
            course: item.levelSubTaskContentId,
            taskLevelId: taskLevelId
          }
        });
      } else {
        this.$router.push({
          name: "exam",
          query: {
            id: item.levelSubTaskContentId,
            subTask: item.levelSubTaskId
          }
        });
      }
    }
  },
  watch: {
    passFlag: function(val) {
      if (val) {
        console.log('视频播放完了!')
        let count = 0
        let isClick = false
        setTimeout(() => {
          this.list.find((item, index) => {
            item.learningTaskSubTaskProgressList.find((ele, id) => {
              if (count === 1) {
                isClick = true
                if (ele.subTaskType === 'EXAM') return item
                this.click(ele, index, id, item.studyType, item.taskLevelId)
                return item
              }
              if (this.levelSubTaskId === ele.levelSubTaskId && this.levelSubTaskContentId === ele.levelSubTaskContentId) count++
            })
            // debugger
            if (count === 1 && isClick) return item
          })
        }, 1000);
      }
    }
  }
};
</script>

<style lang = "scss" scoped>
.task {
  width: 810px;
  min-height: 600px;
}
.task__content{
  min-height: 600px;
}
.nodata{
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 600px;
}
.level {
  box-sizing: border-box;
  &__title {
    line-height: 60px;
    background: #f8f8f8;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333;
    padding: 0 40px;
    border-bottom: 1px solid #f3f3f3;
  }
  &__content {
    box-sizing: border-box;
    
  }
  &__item {
    padding: 0 40px;
    cursor: pointer;
    display: flex;
    align-items: center;
    height: 60px;
    line-height: 60px;
    border-bottom: 1px solid #f3f3f3;
    align-content: center;
    justify-content: flex-start;
    .item_image{
      width: 28px;
      height: 28px;
      margin-right: 10px;
      img{
        width: 100%;
        height: 100%;
        display: block;
      }
    }
    &:hover .level__item-text {
      color: #376cde;
    }
    &-garde {
      width: 39px;
      height: 15px;
      img{
        width: 100%;
        height: 100%;
        display: block;
      }
    }
    &-text {
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #595959;
      &_active {
        color: #1890ff;
      }
    }
  }
}
.size {
  font-size: 14px;
  color: #999999;
}

.task ::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  background-color: #5db4ff;
}
.task ::-webkit-scrollbar-track {
  background: #eee;
}
.task ::-webkit-scrollbar-thumb {
  background: #5db4ff;
  border-radius: 100px;
}
.task ::-webkit-scrollbar-thumb:hover {
  background: #5db4ff;
}
.task ::-webkit-scrollbar-corner {
  background: #5db4ff;
}
</style>
