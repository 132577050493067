<template>
  <div class="learn_box">
    <loading :loading="timesSpining"></loading>
    <div class="learn_banner">
      <img src="@/assets/images/homeImg/learnBanner.png" alt="" />
    </div>
    <div class="learn_wrap">
      <div class="learn_play_left">
        <div class="learn_play_left_title">
          <div class="title_text">{{ learnData.taskName }}</div>
        </div>
        <task
          :list="learnData.learningTaskLevelProgressList"
          :studyType="learnData.studyType"
          :lockFlag="learnData.lockFlag"
          :status="learnData.taskExpireStatus"
        />
      </div>
      <div class="learn_play_right">
        <div class="right_header">
          <div class="right_header_icon"></div>
          <div class="right_header_text">任务情况</div>
        </div>
        <div class="right_item">
          <div class="right_item_desc">总关卡数：</div>
          <div class="right_item_name">{{ learnData.levelCount }}</div>
        </div>
        <div class="right_item">
          <div class="right_item_desc">当前关卡：</div>
          <div
            class="right_item_name"
            v-if="
              learnData != null &&
              learnData.learningTaskLevelProgressList != null
            "
          >
            {{
              learnData.learningTaskLevelProgressList[levelInfo.levelIndex]
                .taskLevelName
            }}
          </div>
        </div>
        <div class="right_item">
          <div class="right_item_desc">已完成：</div>
          <div class="right_item_name">{{ learnData.finishedLevelCount }}</div>
        </div>
        <div class="right_item">
          <div class="right_item_desc">未完成：</div>
          <div class="right_item_name">
            {{ learnData.levelCount - learnData.finishedLevelCount }}
          </div>
        </div>
        <div class="right_item">
          <div class="right_item_desc">任务进度：</div>
          <div class="right_item_name">
            {{
              learnData.completeRatio != null
                ? learnData.completeRatio
                : 0
            }}%
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getTaskData } from "@/api";
import task from "@/components/task-listed";
import loading from "@/components/loading";
import Vue from "vue";
export default {
  components: {
    task,
    loading
  },
  data() {
    return {
      taskId: Vue.ls.get("TASK_ID"),
      taskPathId: Vue.ls.get("TASK_PATH_ID"),
      learnData: {},
      timesSpining:false
    };
  },
  created() {
    this.getTaskData();
  },
  methods: {
    // 获取学习任务
    getTaskData() {
      this.timesSpining = true
      getTaskData({
        taskId: this.taskId,
        taskPathId: this.taskPathId,
      }).then((res) => {
        this.learnData = res.result;
        if(this.learnData.completeRatio != null){
          this.learnData.completeRatio = (this.learnData.completeRatio*100).toFixed(2)
        }
        Vue.ls.set("TASK_NAME", res.result.taskName);
        let level =
          Number(res.result.finishedLevelCount) < Number(res.result.levelCount)
            ? res.result.learningTaskLevelProgressList[
                res.result.finishedLevelCount
              ]
            : res.result.learningTaskLevelProgressList[
                res.result.finishedLevelCount - 1
              ];
        console.log("levellevellevellevel", level);
        let levelIndex =
          Number(res.result.finishedLevelCount) < Number(res.result.levelCount)
            ? res.result.finishedLevelCount
            : res.result.finishedLevelCount - 1;
        let courseIndex = null;
        for (let i = 0; i < level.learningTaskSubTaskProgressList.length; i++) {
          if (
            level.learningTaskSubTaskProgressList[i].progressStatus ==
            "NOT_START"
          ) {
            courseIndex = i;
            break;
          }
        }
        console.log("courseIndex", courseIndex != "");
        let levelInfo = {
          levelIndex: levelIndex,
          courseIndex:
            courseIndex != null
              ? courseIndex
              : level.learningTaskSubTaskProgressList.length - 1,
        };
        this.levelInfo = levelInfo;
      }).finally(() => {
          this.timesSpining = false;
        });
    },
  },
};
</script>

<style lang = "scss" scoped>
.learn_box {
  .learn_banner {
    width: 100%;
    img {
      width: 100%;
      height: auto;
    }
  }
  .learn_wrap {
    width: 1200px;
    padding: 48px 0 192px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    .learn_play_left {
      width: 810px;
      background: #ffffff;
      box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.05);
      border-radius: 15px;
      .learn_play_left_title {
        font-size: 18px;
        line-height: 50px;
        border-bottom: 1px solid #f3f3f3;

        display: flex;
        .title_text {
          font-size: 18px;
          line-height: 50px;
          text-align: left;
          position: relative;
          border-bottom: 2px solid #5db4ff;
          color: #5db4ff;
          margin-left: 60px;
        }
      }
    }
    .learn_play_right {
      width: 350px;
      background: #ffffff;
      box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.05);
      border-radius: 15px;
      height: 320px;
      .right_header {
        padding-left: 20px;
        margin-top: 20px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        .right_header_icon {
          width: 4px;
          height: 20px;
          background: #5db4ff;
          margin-right: 10px;
        }
        .right_header_text {
          font-size: 18px;
          text-align: left;
          color: #333;
          line-height: 40px;
        }
      }
      .right_item {
        display: flex;
        margin: 15px 0 0 36px;
        align-items: center;
        .right_item_desc {
          color: #999;
          font-size: 14px;
          width: 76px;
          text-align: justify;
          text-justify: distribute-all-lines;
          text-align-last: justify;
        }
        .right_item_name{
          font-size: 14px;
          color: #333;
          text-align: left;
        }
      }
    }
  }
}
</style>
